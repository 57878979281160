<template>
    <div class="allmsg">
        <navigation :msg="msg"></navigation>
        <div class="themsgclass">
            <div>提现id：{{$route.query.id}}</div>
            <div><img src="../../assets/success.png"/></div>
            <div>申请提现提交成功</div>
        </div>
        <bottones class="bo" :msg="bumsg"></bottones>
    </div>
</template>
<script>
import navigation from "../../components/navigation/index"
import bottones from "../../components/botton/index"
export default {
    components:{
        navigation,
        bottones
    },
    data(){
        return{
            msg:"提现成功",
            bumsg:"返回"
        }
    },
    methods:{
        clickmeth(){
            this.$router.go(-1);
        }
    }
}
</script>
<style scoped>
.bo{
    margin-top: 3rem;
}
.allmsg{
    background-color: #ffffff;
    padding-bottom: 2rem;
}
.themsgclass>div:first-child{
    text-align: center;
    font-size: .36rem;
    margin-top: 2rem;
}
.themsgclass>div:nth-child(2){
    text-align: center;
    margin-top: .53rem;
    margin-bottom: .72rem;
}
.themsgclass>div:nth-child(2) img{
    width: 1.4rem;
    height: 1.4rem;
}
.themsgclass>div:nth-child(3){
    text-align: center;
    font-size: .4rem;
}

</style>